import styled from 'styled-components'

export const ModalBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #242854d3;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Modal = styled.div`
    width: 25rem;
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    background-color: #fff;
    border-radius: 15px;
`

export const ModalHeader = styled.div`
    width: 100%;
    background-color: #f1f1f1;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    text-align: center;
    padding: 10px;
`

export const ModalBody = styled.div`
    width: 100%;
    background-color: #f1f1f1;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    padding: 15px;
`
