import React from 'react'
import styled from 'styled-components'

export default function ProfesorCard ({
  imgPath,
  name
}) {
  return (
    <Wrapper>
      <img
        src={`/assets/profesores/${imgPath && 'placeholder'}.png`}
        alt={`Imagen del profesor ${name}`}
        loading='lazy'
        style={{
          width: 120
        }}
      />
      {name}
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    padding: .8rem;
    width: 160px;
    
    box-shadow: 
    0px 5px 5px -3px rgb(0 0 0 / 20%), 
    0px 8px 10px 1px rgb(0 0 0 / 14%), 
    0px 3px 14px 2px rgb(0 0 0 / 12%);   
`
