import React from 'react'
import { Link } from 'react-router-dom'

import { FootContainer, Foot, FootList } from '../Styles/StylesFooter'

export default function Footer () {
  const currentYear = new Date().getFullYear()
  return (
    <FootContainer>
      <Foot>
        <FootList>
          <img src='/assets/logo_footer.png' alt='logo del pie de pagina' className='logo' loading='lazy' />
        </FootList>
        <FootList id='categoryList'>
          <li><h3>Cursos</h3></li>
          <li><Link to='/cursos/Kinder'>Kinder</Link></li>
          <li><Link to='/cursos/Juniors'>Juniors</Link></li>
          <li><Link to='/cursos/Teens'>Teens</Link></li>
          <li><Link to='/cursos/Adults'>Adults</Link></li>
          <li><Link to='/cursos/Individuales'>Individuales</Link></li>
          <li><Link to='/cursos/Empresariales'>Empresariales</Link></li>
        </FootList>
        <FootList>
          <li><h3>Nosotros</h3></li>
          <li><Link to='/nosotros'>Quiénes somos</Link></li>
          <li><Link to='/contacto'>Contacto</Link></li>
          <li><Link to='/postulate'>Trabajá con nosotros</Link></li>
          <li><Link to='/faq'>Preguntas frecuentes</Link></li>
        </FootList>
        <FootList>
          <li>
            <p>Seguinos</p>
            <div id='redes'>
              <a target='_blank' href='https://www.facebook.com/englishempirelr' rel='noopener noreferrer'>
                <img src='/assets/icons/__Icono_FB.png' width={30} alt='red social facebook' loading='lazy' />
              </a>
              <a target='_blank' href='https://www.instagram.com/englishempirelr/' rel='noopener noreferrer'>
                <img src='/assets/icons/__Icono_IG.png' width={30} alt='red social instagram' loading='lazy' />
              </a>
              <a target='_blank' href='https://www.tiktok.com/@englishempirelr' rel='noopener noreferrer'>
                <img src='/assets/icons/__Icono_TikTok.png' width={30} alt='red social instagram' loading='lazy' />
              </a>
            </div>
          </li>
        </FootList>
      </Foot>
      <p>Copyright @ {currentYear} English Empire Institute</p>
    </FootContainer>
  )
}
