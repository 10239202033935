import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ScrollToTop from '../Utils/ScrollToTop'
import Loader from './Loader'
// layout
import Layout from '../Layouts/Layout'
import AdminLayout from '../Layouts/AdminLayout'
// layout

import { CourseContainer } from '../Containers'

// web
const Home = lazy(() => import('../Views/Web/Home'))
const About = lazy(() => import('../Views/Web/About'))
const Courses = lazy(() => import('../Views/Web/Courses'))
const Inscription = lazy(() => import('../Views/Web/Inscription'))
const Apply = lazy(() => import('../Views/Web/Apply'))
const Contact = lazy(() => import('../Views/Web/Contact'))
const Faq = lazy(() => import('../Views/Web/Faq'))
// web

// admin
const Login = lazy(() => import('../Views/Admin/Login'))
const Dashboard = lazy(() => import('../Views/Admin/Dashboard'))
const PanelCourses = lazy(() => import('../Views/Admin/PanelCourses'))
const EditCourse = lazy(() => import('../Views/Admin/PanelCourses/edit'))
const AddCourse = lazy(() => import('../Views/Admin/PanelCourses/add'))
const PanelUsers = lazy(() => import('../Views/Admin/PanelUsers'))
const EditUser = lazy(() => import('../Views/Admin/PanelUsers/edit'))
const AddUser = lazy(() => import('../Views/Admin/PanelUsers/add'))
// admin

// error view
const PageErrorWeb = lazy(() => import('../Views/Web/PageErrorWeb'))
const PageErrorAdmin = lazy(() => import('../Views/Admin/PageError'))
// error view

// subdomain config
const subdomain = window.location.hostname.includes('admin')
const webRoutes = [
  { path: 'cursos/:category?', element: <Courses /> },
  { path: 'nosotros', element: <About /> },
  { path: 'cursos/:categoriaCurso/:idCurso', element: <CourseContainer /> },
  { path: 'inscripcion/:idCourse?', element: <Inscription /> },
  { path: 'postulate', element: <Apply /> },
  { path: 'contacto', element: <Contact /> },
  { path: 'faq', element: <Faq /> },
  { path: '*', element: <PageErrorWeb /> }
]

const adminRoutes = [
  { path: 'dashboard', element: <Dashboard /> },
  { path: 'dashboard/courses', element: <PanelCourses /> },
  { path: 'dashboard/courses/:courseId', element: <EditCourse /> },
  { path: 'dashboard/courses/add', element: <AddCourse /> },
  { path: 'dashboard/users', element: <PanelUsers /> },
  { path: 'dashboard/users/:userId', element: <EditUser /> },
  { path: 'dashboard/users/add', element: <AddUser /> },
  { path: '*', element: <PageErrorAdmin /> }
]

function Navigation () {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* {
            subdomain
              // rutas para .admin
              ? <Route>
                <Route index path='/' element={<Login />} />
                <Route element={<AdminLayout />}>
                  {
                    adminRoutes.map(({ path, element }) => (
                      <Route key={path} path={path} element={element} />
                    ))
                  }
                </Route>
                </Route>

              // rutas para englishempire
              : <Route path='/' element={<Layout />}>
                <Route index path='/' element={<Home />} />
                {
                  webRoutes.map(({ path, element }) => (
                    <Route key={path} path={path} element={element} />
                  ))
                }
                </Route>

          } */}
          <Route path='/' element={<Layout />}>
            <Route index path='/' element={<Home />} />
            {
              webRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))
            }
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default Navigation
