import styled from 'styled-components'

import { desktop, mobile, tablet } from '../Constants/breakpoints'

export const Container = styled.div`
    svg{
        fill: #EE1120
    }
`

export const Banner = styled.div`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
    background-color: #252653;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    width: 90%;
    margin: auto;

    background-repeat: no-repeat;
    background-position: center;
    background-size:  cover;

    @media ${desktop}{
        align-items: flex-start;
        padding-left: 4rem;
    }
`

export const BannerTitle = styled.h2`
    color: #fff;
    font-size: 3rem;
    
    @media ${desktop}{
        width: 50%;
    }
`

export const BannerInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
    width: 100%;
    max-width: 720px;
    
`

export const BannerInfoDuration = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    
    p{
        color: #fff;
        font-size: 1.2rem;
    }

    @media ${desktop}{
        display: none;
    }
`

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 4rem;
    margin: 0px auto;

    @media ${desktop}{
        flex-direction: row;
        max-width: 90%
    }

    @media ${mobile} {
        padding: 2rem;
    }
`

export const Description = styled.div`
    font-size: 28px;
    max-width: 65%;

    @media ${mobile}{
        max-width: 100%;
    }

    @media ${tablet}{
        max-width: 100%;
    }
`

export const DownloadProgram = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 1rem;
    gap: 5px;

    h3{
        font-size: 25px;
        color: #EE1120;
    }
`

export const Inscription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .withoutPrice{
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;  
    }

    @media ${desktop}{
        align-self: flex-start;
        position: sticky;
        top: 165px;
        margin-top: -160px;
        margin-left: 100px;
    }

`

export const InscriptionPrice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    padding: 1.8rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    gap: 1rem;

    h2{
        font-size: 2.2rem;
    }

    @media ${desktop}{
        flex-direction: column;
    }
`

export const InscriptionInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    padding: 1.5rem;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    gap: 1rem;

    @media ${desktop}{
        flex-direction: column;
        align-items: flex-start;
    }
`

export const IncriptionInfoDuration = styled.div`
    display: none;
    
    @media ${desktop}{
        display: flex;
        gap: 5px;
        align-self: flex-start;
        align-items: center;
    }
`

export const InscriptionInfoItem = styled.div`
    h4{
        color: #EE1120;
    }
`
