import styled from 'styled-components'
import { desktop, mobile } from '../Constants/breakpoints'

export const FootContainer = styled.div`
    background-color: #242854;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 2rem;
    color: #fff;
    gap: 2rem;

    ul > li,a, h3, p{
        color: #fff;
    }

    h3{
        font-size: 30px;
    }

    a, p{
        font-size: 27px;
    }

    @media ${mobile}{
        padding-top: 1rem;
    }
`

export const Foot = styled.footer`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5rem;
    width: 100%;
    padding-right: 10rem;
    
    .logo{
        padding-top: 15px;
    }
    
    .logo, #categoryList{
        display: none !important;
        
        @media ${desktop} {
            display: block !important;
        }
    }
    
    li{
        list-style: none;
    }
    

    @media ${mobile}{
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        gap: 3rem;
    }
`

export const FootList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: .5rem;

    &#categoryList{
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    #redes{
        display: flex;
        align-items: center;
        gap: .8rem;
        padding-top: .5rem;
    }
`
