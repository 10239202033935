import React from 'react'
import styled from 'styled-components'

export default function CustomInput ({
  hasIcon = '',
  ...restProps
}) {
  const backgroundColor = restProps.type === 'file' && 'transparent'
  return (
    <InputContainer>
      <Input
        style={{ backgroundColor }}
        {...restProps}
      />
      {
        hasIcon !== '' &&
          <label htmlFor={restProps.id}>
            <img src={`/assets/icons/${hasIcon}.png`} className='iconSearch' alt='icono de busqueda' loading='lazy' />
          </label>
      }
    </InputContainer>
  )
}

const InputContainer = styled.div`
  display: flex;
  align-items: center;

  .iconSearch{
    position: relative;
    right: 1.8rem;
  }
`

const Input = styled.input`
  background-color: #f1f1f1;
  border: none;
  outline: none;
  padding: .5rem 1.4rem;
  border-radius: 5px;
  font-size: .8rem;
  width: 100%;
  border: 1px solid transparent;

  &:focus{
    border: 1px solid #252D62;
  }

  &::placeholder{
    color: #CDCDCD
  }
`
