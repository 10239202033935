import styled from 'styled-components'
import { mobile, tablet } from '../Constants/breakpoints'

export const Nav = styled.nav`
    display: flex;
    padding: 0 35px;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    color: #242854;
    /* text-transform: uppercase; */
    position: fixed;
    width: 100%;
    z-index: 3;
    background-color: #F1F1F1;
    border-bottom: 1px solid #dadada;
    /* box-shadow: 0px 10px 25px -8px #dadada; */
    height: 115px;

    li{
        list-style: none;
    }
    
    a{
        color: #1d2355;
        font-size: 28px;
        font-weight: bold;
    }
    
    a:visited{
        color: #1d2355;
    }

    .logo{
        width: 200px;

        @media ${mobile}{
            width: 150px
        }

        @media ${tablet}{
            width: 150px
        }
    }
`

export const NavList = styled.ul`
    display: flex;
    align-items: center;
    gap: 2rem;

    @media ${tablet}{
        display: none;
    }

    @media ${tablet}{
        gap: 1rem;
    }
`

export const NavMobileList = styled.ul`
    position: fixed;
    top: 110px;
    right: 0px;
    background-color: #F1F1F1;
    height: 100%;
    li{
        padding: 15px;
    }
`

export const NavMobileNav = styled.div`
    display: none;
    padding: 15px;

    svg{
        width: 45px;
        height: 45px;
    }

    @media ${tablet}{
        display: block;
    }
`
