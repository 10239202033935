import React from 'react'
import styled from 'styled-components'

export default function CustomTextarea ({
  ...restProps
}) {
  return (
    <Textarea
      {...restProps}
    />
  )
}

const Textarea = styled.textarea`
  background-color: #f1f1f1;
  border: none;
  outline: none;
  padding: .5rem;
  border-radius: 5px;
  height: 50px;
  font-size: .8rem;
  width: 100%;
  height: 6rem;
  max-height: 15rem;
  min-height: 3rem;
  max-width: 100%;
  min-width: 100%;

  &:focus{
    border: 1px solid #252D62;
  }
`
