import React from 'react'
import styled from 'styled-components'

export default function CustomDropdown ({
  content,
  ref,
  ...restProps
}) {
  return (
    <Dropdown
      ref={ref}
      {...restProps}
    >
      <option value='' key={0}>Seleccionar...</option>
      {
            content?.map(({ id, name }, index) => (
              <option value={id} key={index}>{name}</option>
            ))
        }
    </Dropdown>
  )
}

export const Dropdown = styled.select`
  background-color: #f1f1f1;
  border: none;
  outline: none;
  padding: .5rem;
  border-radius: 5px;
  font-size: .8rem;
  width: 100%;

  &:focus{
    border: 1px solid #252D62;
  }
`
