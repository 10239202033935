import React from 'react'
import styled from 'styled-components'

export default function Loader () {
  return (
    <Wrapper>
      <div className='loader' />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  height: 100vh;
  
  .loader {
    position: fixed;
    width: 120px;
    padding: 12px;
    background: #EE1120;
    display: flex;
    justify-content: center;
    
    aspect-ratio: 1;
    border-radius: 50%;
    --_m: 
      conic-gradient(#0000,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    box-sizing: border-box;
    animation: load 1s linear infinite;
  }

@keyframes load {
  to{transform: rotate(1turn)}
}
`
