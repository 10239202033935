import React from 'react'
import { Link } from 'react-router-dom'

export default function LinkButton ({
  label,
  path,
  className,
  ...restProps
}) {
  return (
    <Link
      to={path}
      className={className}
      {...restProps}
    >
      {label}
    </Link>
  )
}
