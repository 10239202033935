import React from 'react'
import { LinkButton } from '../../../Components'

import {
  Container,
  Banner,
  BannerTitle,
  BannerInfo,
  BannerInfoDuration,
  Body,
  Description,
  DownloadProgram,
  Inscription,
  InscriptionPrice,
  InscriptionInfo,
  InscriptionInfoItem,
  IncriptionInfoDuration
} from '../../../Styles/StylesCourseDetail'

export default function CourseDetail ({ courseSelected }) {
  function formatToHTMLTag (description) {
    // reemplazo \n por <br>
    let formattedText = description.replace(/\n/g, '<br>')
    // reemplazo la etiqueta lo que esté dentro de <title> por un h3
    formattedText = formattedText.replace(/<title>(.*?)<\/title>/g, '<h3>$1</h3>')

    return formattedText
  }
  const formattedDescription = formatToHTMLTag(courseSelected?.description)
  return (
    <Container>
      <Banner
        style={{ backgroundImage: `url(/assets/courses/banners/${courseSelected?.bannerPath}.png)` }}
      >
        <BannerTitle>{courseSelected?.name}</BannerTitle>
        <BannerInfo>
          {
            courseSelected?.category === 'empresariales' || courseSelected?.category === 'individuales'
              ? <BannerInfoDuration>
                <p>Presencial u online</p>
                </BannerInfoDuration>
              : <>
                <BannerInfoDuration>
                  <img src='/assets/icons/__Icono_semanas.png' alt='duracion de las clases' loading='lazy' />
                  <p>{courseSelected?.weeks}</p>
                </BannerInfoDuration>
                <BannerInfoDuration>
                  <img src='/assets/icons/__Icono_frecuencia.png' alt='cantidad de clases por semana' loading='lazy' />
                  <p>{courseSelected?.classWeek}</p>
                </BannerInfoDuration>
              </>

          }
        </BannerInfo>
      </Banner>

      <Body>

        <Description>
          <p dangerouslySetInnerHTML={{ __html: formattedDescription }} />
          {/* {
            courseSelected?.category === 'empresariales' || courseSelected?.category === 'individuales'
              ? null
              : <DownloadProgram href={courseSelected?.downloadLink} target='_blank' rel='noopener noreferrer'>
                <img src='/assets/icons/__Icono_descarga_programa.png' alt='descargar programa' loading='lazy' />
                <h3>Programa completo</h3>
              </DownloadProgram>
          } */}
        </Description>

        <Inscription>
          {
            courseSelected?.category === 'empresariales' || courseSelected?.category === 'individuales'
              ? <InscriptionPrice className='withoutPrice'>
                <p style={{ textAlign: 'center' }}>¡Escribinos para concretar una reunión!</p>
                <LinkButton
                  path='/contacto'
                  className='CTA'
                  label='Consultar'
                />
                </InscriptionPrice>
              : <>
                <InscriptionPrice>
                  <h2>${courseSelected?.price}</h2>
                  <LinkButton
                    path={`/inscripcion/${courseSelected?.id}`}
                    className='CTA'
                    label='Inscribirme'
                  />
                  {/* <IncriptionInfoDuration>
                                  <p>Cupos disponibles <span style={{color: "#ee1120", fontWeight: "bold"}}>{courseSelected?.cupos}</span></p>
                              </IncriptionInfoDuration> */}
                  <IncriptionInfoDuration>
                    <img src='/assets/icons/__Icono_semanas.png' alt='duracion de las clases' loading='lazy' />
                    <p>{courseSelected?.weeks}</p>
                  </IncriptionInfoDuration>
                  <IncriptionInfoDuration>
                    <img src='/assets/icons/__Icono_frecuencia.png' alt='cantidad de clases por semana' loading='lazy' />
                    <p>{courseSelected?.classWeek}</p>
                  </IncriptionInfoDuration>
                </InscriptionPrice>
                <InscriptionInfo>
                  {/* <InscriptionInfoItem>
                    <h4>Profesor a cargo</h4>
                    <p>{courseSelected?.teacher}</p>
                  </InscriptionInfoItem> */}
                  <InscriptionInfoItem>
                    <h4>Fecha de inicio</h4>
                    <p>{courseSelected?.startDate}</p>
                  </InscriptionInfoItem>
                  <InscriptionInfoItem>
                    <h4>Fecha de finalización</h4>
                    <p>{courseSelected?.finishDate}</p>
                  </InscriptionInfoItem>
                </InscriptionInfo>
                </>

          }

        </Inscription>
      </Body>
    </Container>
  )
}
