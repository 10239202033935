import styled from 'styled-components'

export const Card = styled.div`
  display: flex;  
  border-radius: 20px;
  background-color: #f1f1f1;
  gap: 1rem;
  padding-right: .5rem;
`

export const CardDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: .5rem;
    font-size: 15px;
    flex: 1;
`
