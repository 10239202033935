import * as React from 'react'
import { Card, CardDescription } from '../Styles/StylesCards'

export default function CourseCard ({
  imgPath,
  courseName,
  courseSubname
}) {
  return (
    <Card>
      <img src={imgPath} alt={courseName} width='50%' loading='lazy' />
      <CardDescription>
        <h2>{courseName}</h2>
        <p>{courseSubname}</p>
      </CardDescription>
    </Card>
  )
}
