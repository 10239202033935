import React from 'react'
import { Outlet } from 'react-router-dom'
import { Navbar, Footer } from '../Components'
import styled from 'styled-components'

export default function Layout () {
  return (
    <Çontainer>
      <Navbar />
      <Outlet />
      <Footer />
    </Çontainer>
  )
}

const Çontainer = styled.div`

`
