import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import data from '../Data/courses.json'
import CourseDetail from '../Views/Web/CourseDetail'
import { Loader } from '../Components'

export default function CourseContainer () {
  const [courseSelected, setCourseSelected] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { idCurso } = useParams()

  useEffect(() => {
    // busco en el json el curso seleccionado
    const curso = data.filter(({ id }) => id === parseInt(idCurso))[0]
    // hago que la primer letra de la categoria sea mayuscula para no modificar todo el json
    const categoriaFormateada = curso.category.charAt(0).toUpperCase() + curso.category.slice(1).toLowerCase()
    // lo agrego al objeto para usarlo en el front.
    setCourseSelected({ ...curso, categoriaFormateada })
    setIsLoading(false)
    return () => {}
  }, [idCurso])

  if (isLoading === true) {
    return <Loader />
  }

  return (
    <section>
      <CourseDetail
        courseSelected={courseSelected}
      />
    </section>
  )
}
