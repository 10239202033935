import React from 'react'
import { ModalBackground, Modal, ModalHeader, ModalBody } from '../Styles/StylesModal'

export default function ModalMessage ({
  title,
  message,
  closeModal
}) {
  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalBody>
          <p>{message}</p>
          <div className='flex gap-4 flex-wrap justify-center'>
            <button className='CTA' onClick={closeModal}>Cerrar</button>
          </div>
        </ModalBody>
      </Modal>
    </ModalBackground>
  )
}
