import React, { useEffect, useState } from 'react'
import { HashLink as LinkHash } from 'react-router-hash-link'
import { Link, useLocation } from 'react-router-dom'
import LinkButton from './LinkButton'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

import { Nav, NavList, NavMobileNav, NavMobileList } from '../Styles/StylesNavbar'

export default function Navbar () {
  const location = useLocation()
  const [menuVisible, setMenuVisible] = useState(false)

  const handleMenu = () => {
    setMenuVisible(!menuVisible)
  }

  // cada vez que cambia la locación, se cierra el menú
  useEffect(() => {
    setMenuVisible(false)
  }, [location])

  return (
    <Nav>
      <LinkHash to='/'><img src='/assets/logo.png' alt='logo' className='logo' loading='lazy' /></LinkHash>
      <NavMobileNav>
        {
                !menuVisible
                  ? <MenuIcon onClick={handleMenu} />
                  : <CloseIcon onClick={handleMenu} />
            }
        {
                menuVisible &&
                  <NavMobileList>
                    <li> <LinkHash to='/'>Inicio</LinkHash></li>
                    <li> <LinkButton label='Cursos' path='/cursos' /></li>
                    <li> <LinkButton label='Inscripciones' path='/inscripcion' /></li>
                    <li> <LinkButton label='Nosotros' path='/nosotros' /></li>
                    <li> <LinkButton label='Trabaja con nosotros' path='/postulate' /></li>
                    <li> <Link to='/contacto'>Contacto</Link></li>
                  </NavMobileList>
            }
      </NavMobileNav>
      <NavList>
        <li> <LinkHash to='/'>Inicio</LinkHash></li>
        <li> <LinkButton label='Cursos' path='/cursos' /></li>
        <li> <LinkButton label='Nosotros' path='/nosotros' /></li>
        <li> <LinkButton label='Trabaja con nosotros' path='/postulate' /></li>
        <li> <Link to='/contacto'>Contacto</Link></li>
      </NavList>
    </Nav>
  )
}
