import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import cursos from '../Data/courses.json'
import { Link } from 'react-router-dom'
import { CourseCard } from '../Components'

import { mobile, tablet } from '../Constants/breakpoints'

export default function FilterCoursesContainer ({
  filter,
  chipSelected
}) {
  const [filteredCourses, setFilteredCourses] = useState()

  useEffect(() => {
    const searchParam = filter.toLowerCase()
    const chip = chipSelected.toLowerCase()

    const result = cursos.filter((course) => {
      const nameLower = course.name.toLowerCase()
      const categoryLower = course.category.toLowerCase()
      const subname = course.subname.toLowerCase()

      const nameMatch = nameLower.match(new RegExp(searchParam, 'i'))
      const categoryMatch = categoryLower.match(new RegExp(searchParam, 'i'))
      const subnameMatch = subname.match(new RegExp(searchParam, 'i'))
      const chipMatch = categoryLower.match(new RegExp(chip, 'i'))

      if (filter === '') {
        return chipMatch
      } else {
        return nameMatch || categoryMatch || subnameMatch
      }
    })

    setFilteredCourses(result)
  }, [filter, chipSelected])

  return (
    <Wrapper>
      {
        filteredCourses?.map(({ id, category, name, subname, imgPath }) => (
          <Link to={`/cursos/${category}/${id}`} key={id}>
            <CourseCard
              courseName={name}
              courseSubname={subname}
              imgPath={`/assets/courses/${category}/${imgPath}.png`}
            />
          </Link>
        ))
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 3rem;
    
    a{
      width: 22rem;
    }
    
    @media ${tablet}{
      padding-left: 0;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      margin: auto;
    }
    
    @media ${mobile}{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      padding-left: 0;
      margin: auto;
    }
    
`
